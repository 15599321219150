import cardAction from '@/templates/projects/_cardAction.js'
import listChip from '@/templates/cards/_listChip.js'
import memberAvatars from '@/templates/cards/_memberAvatars.js'

export default class {
  static accessory(taskCard) {
    const leftViews = taskCard.shapeUp.list ? [{
      "view": "panels/horizontal",
      "height": "matchParent",
      "align": "middle",
      "childViews": [listChip.spec(taskCard)]
    }] : []
    const centerViews = this.accessoryViews(taskCard)

    return {
      "width": "matchParent",
      // "styleClasses": ["display-on-hover"],
      // "padding": { "top": 4 },
      "childViews": [
        {
          "view": "panels/split",
          "width": "matchParent",
          "height": "matchParent",
          "left": {
            "childViews": leftViews
          },
          "center": {
            "height": "matchParent",
            "padding": { "left": centerViews.length > 0 ? 8 : 0, "right": 4 },
            "childViews": centerViews
          },
          "right": {
            "height": "matchParent",
            "childViews": [{
                "view": "panels/horizontal",
                "height": "matchParent",
                "align": "middle",
                "childViews": [memberAvatars.spec(taskCard, true)]
              }
            ]
          },
        }
      ]
    }
  }

  static accessoryViews(taskCard) {
    let completedItemCount = 0
    let allItemCount = 0
    const checklists = taskCard.shapeUp.checklists
    if (checklists) {
      checklists.forEach((checklist) => {
        allItemCount += checklist.checkItems.length

        checklist.checkItems.forEach((checkItem) => {
          if (checkItem.state == "complete") {
            completedItemCount += 1
          }
        })
      })
    }

    let checklistViews = []
    if (taskCard.desc) {
      checklistViews.push(
        {
          "view": "icon",
          "padding": { top: 2, right: 4 },
          "material": {
            "name": $tpu.keys.icnDescription(),
            "size": 18
          },
        }
      )
    }

    const completed = completedItemCount == allItemCount

    if (allItemCount > 0) {
      checklistViews.push(
        {
          "view": "chip",
          "styleClasses": $tpu.styles.chipClasses().concat(["chip-square"]),
          "backgroundColor": completed ? $tpu.styles.completedColor() : null,
          "color": completed ? "#ffffff" : null,
          "text": `☑ ${completedItemCount}/${allItemCount}`
        }
      )
    }

    return [
      {
        "view": "panels/horizontal",
        // "width": "matchParent",
        "height": "matchParent",
        "align": "middle",
        "distribution": "space-1",
        "childViews": checklistViews
      }
    ]
  }

  static spec(taskCard) {
    const placement = $tpu.globals.isAttachmentView ? "right" : "footer"
    return {
      "template": "thumbnail",
      "id": $tpu.keys.toHtmTaskId(taskCard.id),
      "title": taskCard.name,
      "styleClasses": ["card", "task", $tpu.globals.isAttachmentView ? "split" : "non-split"],
      "onClick": cardAction.spec(taskCard, "task"),
      "rightButtons": [
        cardAction.actionIconSpec(taskCard, "task")
      ],
      [placement]: this.accessory(taskCard)
    }
  }
}
